





























import Vue from 'vue';
import Component from 'vue-class-component';
import MainHeader from '@/components/layout/MainHeader.vue';
import MainFooter from '@/components/layout/MainFooter.vue';
import $ from 'jquery';

@Component({ components: { MainHeader, MainFooter } })
export default class BaseLayout extends Vue {
  public selectLang: string = '';
  public scroll: boolean = false;
  public header: boolean = false;
  public scrollInfo: any = {};
  public appBanner: boolean = this.$cookies.get('PoingAppBanner') === 'Y' ? false : true;

  public onScroll(info: any) {
    this.scrollInfo = info;
    if (info.position > 0) {
      if (window.scrollY > 120) {
        return [
          this.scroll = true,
          this.header = true
        ]
      }
      return [
        this.scroll = true,
        this.header = false
      ]
    }
    return this.scroll = false;
  }

  public created() {
    if (localStorage.getItem('OS_DEPOSIT_LANG')) {
      // if (
      //   location.href.includes('lang=en') &&
      //   String(localStorage.getItem('OS_DEPOSIT_LANG')) !== 'en'
      // ) {
      //   this.selectLang = 'en';
      //   localStorage.setItem('OS_DEPOSIT_LANG', 'en');
      //   return;
      // }
      this.selectLang = String(localStorage.getItem('OS_DEPOSIT_LANG'));
      localStorage.setItem('OS_DEPOSIT_LANG', String(localStorage.getItem('OS_DEPOSIT_LANG')));
    } else {
      // if (location.href.includes('lang=en')) {
      //   this.selectLang = 'en';
      //   localStorage.setItem('OS_DEPOSIT_LANG', 'en');
      //   return;
      // }
      this.selectLang = 'ko'
      localStorage.setItem('OS_DEPOSIT_LANG', 'ko');
    }
    if (
      location.href.includes('lang=en') &&
      this.selectLang !== 'en'
    ) {
      setTimeout(() => {
        $('.translate-btn').click();
        setTimeout(() => {
          $('span[title=EN]').click();
        }, 200)
      }, 200);
    }
    setTimeout(() => {
      if (
        location.href.includes('lang=en') &&
        this.selectLang !== 'en'
      ) {
        setTimeout(() => {
          $('.translate-btn').click();
          setTimeout(() => {
            $('span[title=EN]').click();
          }, 200)
        }, 200);
      }
    }, 3000);
    if (
      location.href.includes('lang=jp') &&
      this.selectLang !== 'ja'
    ) {
      setTimeout(() => {
        $('.translate-btn').click();
        setTimeout(() => {
          $('span[title=JP]').click();
        }, 200)
      }, 200);
    }
    if (this.$cookies.get('todayAppDownload') === 'Y') {
      //
    } else {
      this.appBanner = false;
    }
  }
  public langUpdate(data: any) {
    this.selectLang = data;
    localStorage.setItem('OS_DEPOSIT_LANG', data);
  }
}
