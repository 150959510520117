var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-layout',{class:{
    'on-scroll': _vm.scroll,
    'simple-layout': _vm.$route.meta.simpleLayout,
    'none-header-layout': _vm.$route.meta.noneHeader,
    'none-header': _vm.$route.meta.noneHeader,
    'padding-none-layout': _vm.$route.name === 'banner' || _vm.$route.name === 'search-result'
  },attrs:{"view":"lHh lpR lFf"},on:{"scroll":_vm.onScroll}},[_c('main-header',{on:{"update":function($event){_vm.appBanner = false}}}),_c('q-page-container',{class:{
      'app-banner': _vm.appBanner && !_vm.$q.platform.is.desktop
    }},[_c('router-view')],1),_c('main-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }