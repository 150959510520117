





























































import { Vue, Component } from 'vue-property-decorator';

@Component
export default class MainFooter extends Vue {
  public footerMenu1: boolean = false;
  public footerMenu2: boolean = false;
}
